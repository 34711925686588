<template>
  <cui-acl :roles="['underDevelopment']" redirect v-if="loaded">
    <a-spin :spinning="loading">
      <h4>
      <span>Lønnsutregning {{ payroll.title }}</span>
      </h4>
      <div class="card">
        <div class="card-body">
          <h4>Sammendrag</h4>
          <div class="row">
            <div class="col-md-4">
              Til utbetaling:
              <h5>{{ sumPayLines(payroll.payLines) }}</h5>
            </div>
          </div>
          <div class="col-md-12" v-if="payroll.status === 'underCalculation'">
            <a-button @click="deletePayroll" >Slett Lønnskjøring</a-button>
            <a-button @click="downloadData" >Exporter til Tripletex</a-button>
            <a-button @click="payrollDone" >Bekreft betaling i Tripletex</a-button>
            <a-button @click="payrollSave" >Lagre</a-button>
          </div>
          <div class="col-md-12" v-if="payroll.status === 'paid'">
            <a-button @click="cancelPayroll" >Avbryt Lønnskjøring</a-button>
          </div>
        </div>
      </div>
      <a-collapse accordion>
        <a-collapse-panel :header="user.employeeID.concat(' ', user.fullName, ' (', user.department, ')', ' ', warning(user))" v-for="(user) in payroll.users" :key="user.key">
          <!-- {{ JSON.stringify(user) }} -->
          <div class="row">
            <div class="col-md-2">
              <a-avatar :size="64" icon="user" :src="user.imageUrl"/>
            </div>
            <div class="col-md-10">
              <h4>{{ user.employmentType }} - {{ user.employeeID }} {{ user.fullName }} {{ user.department }} {{ user.employmentTime }}</h4>
            </div>
            <div class="col-md-12">
              <a-table :columns="payLineColumns" :data-source="payroll.payLines.filter(payLine => payLine.EmployeeNumber === user.employeeID)" bordered class="mt-3" :pagination="false">
                <template slot="wageType" slot-scope="text, record">
                  <dropdown-cell :text="text" @change="onCellChange(record.key, 'type', $event)" :options="wageTypeOptions" v-if="!record.blocked"/>
                  <div v-else>{{ getWageTypeName(text, wageTypeNames) }}</div>
                </template>
                <template slot="count" slot-scope="text, record">
                  <editable-cell :text="String(text)" @change="onCellChange(record.key, 'Count', $event)" v-if="!record.blocked"/>
                  <div v-else>{{ text }}</div>
                </template>
                <template slot="amount" slot-scope="text, record">
                  <editable-cell :text="String(text)" @change="onCellChange(record.key, 'Amount', $event)" v-if="!record.blocked"/>
                  <div v-else>{{ text }}</div>
                </template>
                <template slot="sum" slot-scope="text, record">
                  {{ Number(record.Amount) * Number(record.Count) }}
                </template>
                <template slot="action" slot-scope="text, record">
                  <a-button @click="deletePayLine(record.key)" v-if="!record.blocked"><i class="fa fa-trash"></i></a-button>
                </template>
                <template slot="comment" slot-scope="text, record">
                    <editable-cell :text="String(text)" @change="onCellChange(record.key, 'Comment', $event)" v-if="!record.blocked"/>
                    <div v-else>{{ text }}</div>
                </template>
                <template slot="footer" slot-scope="currentPageData">
                  Til utbetaling: {{ sumPayLines(currentPageData) }}
                  <!-- add to the left a button called new line -->
                  <a-button @click="addPayLine(user)" style="float: right;">
                    Ny Lønnsrad
                  </a-button>
                </template>
              </a-table>
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
      <!-- <a-pagination v-model="currentPage" :total="data.length" show-less-items class="mt-3" style="width: 100%; text-align: center" /> -->
    </a-spin>
  </cui-acl>
</template>

<script>
import { firestore } from '@/services/firebase'
import cuiAcl from '@/components/cleanui/system/ACL'
import moment from 'moment'
import { openNotification } from '@/services/powerAutomate'
import { calculateUsers, sumPayLines, fetchWageTypeNames, changeTransactionsStatus, getWageTypeName } from '@/services/common/payroll'
// import dash from 'lodash'
import exportFromJSON from 'export-from-json'
import DropdownCell from '@/components/custom/visual/dropdownCell'
import EditableCell from '@/components/custom/visual/editableCell'

export default {
  components: {
    cuiAcl,
    DropdownCell,
    EditableCell,
  },
  data() {
    return {
      activeKey: 1,
      users: [],
      loaded: false,
      loading: false,
      payroll: {},
      wageTypeNames: {},
      wageTypeOptions: [],
      payLineColumns: [
        {
          title: 'Lønnsrader',
          dataIndex: 'WageTypeNumber',
          // customRender: (text) => getWageTypeName(text, this.wageTypeNames),
          scopedSlots: { customRender: 'wageType' },
        },
        {
          title: 'Prosjekt',
          dataIndex: 'ProjectNumber',
        },
        {
          title: 'Avdeling',
          dataIndex: 'DepartmentNumber',
        },
        {
          title: 'Antall',
          dataIndex: 'Count',
          scopedSlots: { customRender: 'count' },

        },
        {
          title: 'Sats',
          dataIndex: 'Amount',
          scopedSlots: { customRender: 'amount' },
        },
        {
          title: 'Til utbetaling',
          dataIndex: 'sum',
          scopedSlots: { customRender: 'sum' },
        },
        {
          title: 'Slett',
          dataIndex: 'delete',
          scopedSlots: { customRender: 'action' },
        },
        {
          title: 'Kommentar',
          dataIndex: 'Comment',
          scopedSlots: { customRender: 'comment' },
        },
      ],

    }
  },
  async mounted() {
    const { wageTypeNames, wageTypeOptions } = await fetchWageTypeNames()
    this.wageTypeOptions = wageTypeOptions
    this.wageTypeNames = wageTypeNames
    console.log(this.wageTypeOptions)
    this.getPayroll()
  },
  // watch: {
  //   'payroll.payLines': {
  //     handler: async function (val, oldVal) {
  //       console.log('payroll.payLines changed')
  //       this.payroll.users = await calculateUsers(val, this.payroll.transactions)
  //       this.$forceUpdate()
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    payrollSave() {
      this.loading = true
      firestore.collection('payrolls').doc(this.$route.params.payrollId).update({
        payLines: this.payroll.payLines,
      })
        .then(() => {
          this.loading = false
          openNotification('Lønnsutregning lagret', 'Lønnsutregning er lagret')
        })
    },
    sumPayLines,
    getWageTypeName,
    async getPayroll() {
      console.log(this.$route.params.payrollId)
      firestore.collection('payrolls').doc(this.$route.params.payrollId).get()
        .then(async (doc) => {
          this.payroll = doc.data()
          delete this.payroll.users
          this.payroll.users = await calculateUsers(this.payroll.payLines, this.payroll.transactions, this.payroll.period)
          this.loaded = true
          // add a key to each payline
          this.payroll.payLines.forEach((payLine, index) => {
            payLine.key = index
          })
        })
    },
    warning(user) {
      // find payLines of user
      const payLines = this.payroll.payLines.filter((payLine) => payLine.EmployeeNumber === user.employeeID)
      let warning = ''
      // find totalAmount for the paylines
      const totalAmount = sumPayLines(payLines)
      // return true if totalAmount is less than 10000
      // console.log(user.employmentTime)
      if (user.employmentTime === 'full-time') {
        warning = totalAmount < 10000 ? '⚠️' : ''
      }
      return warning
    },
    addPayLine(user) {
      const ProjectNumber = user.payLines[0].ProjectNumber
      const payLine = {
        EmployeeNumber: user.employeeID,
        WageTypeNumber: '2040',
        ProjectNumber,
        DepartmentNumber: user.department,
        Count: 1,
        Amount: 0,
        key: this.payroll.payLines.length,
      }
      this.payroll.payLines.push(payLine)
    },
    deletePayLine(index) {
      const payLine = this.payroll.payLines[index]
      if (!payLine.blocked) {
        this.payroll.payLines.splice(index, 1)
      } else {
        openNotification('Kan ikke slette', 'Du kan ikke slette en låst lønnsrad')
      }
    },
    // async updateData() {
    //   const ref = firestore.collection('payrollTransactions')
    //   const { payroll } = this
    //   const transactions = []
    //   const querySnapshot = await ref
    //     .where('year', '==', payroll.year)
    //     .where('month', '==', payroll.month)
    //     .where('project', 'in', payroll.projects)
    //     .where('status', '==', 'unpaid')
    //     .get()

    //   querySnapshot.forEach((doc) => {
    //     const transaction = doc.data()
    //     this.payroll.transactions.push(doc.id)
    //     transactions.push(transaction)
    //   })
    //   const summary = transactions.reduce((accumulator, transaction) => {
    //     const key = `${transaction.year}-${transaction.month}-${transaction.employeeID}-${transaction.type}-${transaction.project}-${transaction.department}`
    //     if (!accumulator[key]) {
    //       accumulator[key] = {
    //         Year: transaction.year,
    //         Month: transaction.month,
    //         EmployeeNumber: transaction.employeeID,
    //         WageTypeNumber: transaction.type,
    //         Comment: transaction.comment,
    //         Count: 0,
    //         Amount: 0,
    //         ProjectNumber: transaction.project,
    //         DepartmentNumber: transaction.department,
    //       }
    //     }
    //     // accumulator[key].key = transaction.employeeID.concat(transaction.type)
    //     accumulator[key].Count = 1
    //     accumulator[key].Amount += Math.round(Number(transaction.amount) * 100) / 100

    //     return accumulator
    //   }, {})
    //   this.payroll.payLines = Object.values(summary)
    //   this.usersArray()
    // },
    downloadData() {
      const orderedKeys = [
        'Year',
        'Month',
        'EmployeeNumber',
        'WageTypeNumber',
        'Comment',
        'Count',
        'Amount',
        'ProjectNumber',
        'DepartmentNumber',
      ]

      const filteredPayLines = this.payroll.payLines.filter((item) => {
        const user = this.payroll.users.find((user) => user.employeeID === item.EmployeeNumber)
        return user && user.employmentType === 'employee'
      })

      const orderedData = filteredPayLines.map((item) => {
        const orderedItem = {}
        orderedKeys.forEach((key) => {
          orderedItem[key] = item[key]
        })
        return orderedItem
      })

      const fileName = this.payroll.title
      const exportType = exportFromJSON.types.csv
      const delimiter = ';'
      exportFromJSON({ data: orderedData, fileName, exportType, delimiter })
    },
    async payrollDone() {
      this.loading = true
      const payroll = this.payroll
      payroll.users = await calculateUsers(payroll.payLines, payroll.transactions, payroll.period)
      firestore.collection('payrolls').doc(this.$route.params.payrollId).update({
        status: 'paid',
        generatedBy: this.$store.state.user.employeeID,
        generatedByName: this.$store.state.user.name,
        datePaid: String(moment().format('YYYY-MM-DDTHH:mm')),
      }).then(() => {
        console.log('done')
        changeTransactionsStatus('paid', this.payroll.transactions)
      }).then(async () => {
        await this.generatePayslips(payroll) // Add this line to generate payslips
        this.payroll.status = 'paid'
        openNotification('Lønnskjøring betalt')
      }).catch(err => {
        console.log(err)
        openNotification('Feil ved generering av Lønnskjøring, vennligst prøv igjen')
      }).finally(() => {
        this.loading = false
      })
    },
    async deletePayroll() {
      if (this.payroll.status !== 'underCalculation') {
        // Show an error message or return early
        openNotification('Cannot delete this payroll. It is not under calculation.')
        return
      }

      try {
        this.loading = true

        // Change the status of the payrollImports to "unblocked"
        const payrollImportsRef = firestore.collection('payrollImports')
        const payrollImportsBatch = firestore.batch()
        this.payroll.payrollImportDocRefs.forEach((docRef) => {
          payrollImportsBatch.update(payrollImportsRef.doc(docRef), { status: 'unblocked' })
        })

        // Change the status of the transactions to 'unpaid'
        await changeTransactionsStatus('unpaid', this.payroll.transactions)

        // Delete the payroll from Firestore
        await firestore.collection('payrolls').doc(this.$route.params.payrollId).delete()

        // Update the payrollImports documents in Firestore with the new status
        await payrollImportsBatch.commit()

        // Redirect the user to the payrolls page
        this.$router.push({ path: '/payroll/payrolls/' })

        // Show a success message
        openNotification('Payroll deleted successfully')
        this.loading = false
      } catch (err) {
        console.log(err)
        openNotification('Error deleting the payroll. Please try again')
        this.loading = false
      }
    },
    async generatePayslips(payroll) {
      const payslipsRef = firestore.collection('payslips')
      const batch = firestore.batch()
      for (const user of payroll.users) {
        const payslip = {
          employeeID: user.employeeID,
          employeeType: user.employmentType,
          payrollID: this.$route.params.payrollId,
          totalAmount: this.sumPayLines(user.payLines),
          transactionIDs: user.transactionIDs,
          payLines: user.payLines,
          period: payroll.period,
          company: payroll.company,
        }
        const newPayslipRef = payslipsRef.doc()
        batch.set(newPayslipRef, payslip)
      }

      await batch.commit()
    },
    cancelPayroll() {
      this.loading = true
      console.log(this.payroll.users)
      firestore.collection('payrolls').doc(this.$route.params.payrollId).update({
        status: 'underCalculation',
      }).then(() => {
        return changeTransactionsStatus('unpaid', this.payroll.transactions)
      }).then(() => {
        return this.deletePayslips() // Add this line to generate payslips
      }).then(() => {
        this.payroll.status = 'underCalculation'
        openNotification('Lønnskjøring avbrutt')
      }).catch(err => {
        console.log(err)
        openNotification('Feil ved avbryting av Lønnskjøring, vennligst prøv igjen')
      }).finally(() => {
        this.loading = false
      })
    },
    deletePayslips() {
      return firestore.collection('payslips').where('payrollID', '==', this.$route.params.payrollId).get()
        .then((querySnapshot) => {
          const batch = firestore.batch()
          querySnapshot.forEach((doc) => {
            batch.delete(doc.ref)
          })
          return batch.commit()
        })
    },
    onCellChange(key, dataIndex, value) {
      console.log(key, dataIndex, value)
      const dataSource = this.payroll.payLines
      const target = dataSource.find(item => item.key === key)
      console.log(target)
      if (target) {
        target[dataIndex] = value
        this.payroll.payLines = dataSource
        this.$forceUpdate()
      }
    },
  },
}
</script>
