import { firestore } from '@/services/firebase'

// function to get all users from pay lines
export async function calculateUsers(payLines, transactions, period) {
  const uniqueEmployeeIDs = [
    ...new Set(payLines.map((transaction) => transaction.EmployeeNumber)),
  ]
  const usersPromises = uniqueEmployeeIDs.map((employeeID) =>
    getUserData(employeeID, period),
  )
  const usersData = await Promise.all(usersPromises)
  const users = usersData.map((userData, index) => {
    const payLinesC = payLines.filter(
      (transaction) => transaction.EmployeeNumber === userData.employeeID,
    )
    // get all transaction IDs for this user
    const transactionIDs = payLinesC.reduce((accumulator, transaction) => {
      if (transaction.TransactionIDs) {
        accumulator.push(...transaction.TransactionIDs)
      }
      return accumulator
    }, [])
    // get all payroll import doc refs for this user
    const payrollImportDocRefs = transactionIDs.reduce((accumulator, transactionID) => {
      const transaction = transactions.find((t) => t.id === transactionID)
      if (transaction && transaction.payrollImportDocRef && !accumulator.includes(transaction.payrollImportDocRef)) {
        accumulator.push(transaction.payrollImportDocRef)
      }
      return accumulator
    }, [])
    return {
      key: String(index + 1),
      ...userData,
      payLines: payLinesC,
      transactionIDs,
      payrollImportDocRefs,
    }
  })
  return users.sort((a, b) => {
    if (a.department < b.department) {
      return -1
    } else if (a.department > b.department) {
      return 1
    } else {
      if (a.fullName < b.fullName) {
        return -1
      } else if (a.fullName > b.fullName) {
        return 1
      } else {
        return 0
      }
    }
  })
}

// function to getUserData from employeeID
export async function getUserData(employeeID, period) {
  const usersRef = firestore.collection('users')
  const querySnapshot = await usersRef.where('employeeID', '==', employeeID).get()

  if (!querySnapshot.empty) {
    const userDoc = querySnapshot.docs[0]
    const positions = userDoc.data().positions

    // Filter positions based on the specified period
    const relevantPosition = positions.find(position => {
      const startDate = new Date(position.startDate)
      const endDate = position.endDate ? new Date(position.endDate) : new Date()

      const periodYear = parseInt(period.slice(0, 4), 10)
      const periodMonth = parseInt(period.slice(4, 6), 10)
      const periodDate = new Date(periodYear, periodMonth - 1)

      return periodDate >= startDate && periodDate <= endDate
    })

    const userData = {
      employeeID: userDoc.data().employeeID,
      fullName: userDoc.data().fullName,
      department: userDoc.data().department,
      status: userDoc.data().status,
      imageUrl: userDoc.data().imageUrl || '',
      employmentType: userDoc.data().type,
      employmentTime: relevantPosition ? relevantPosition.employmentType : null,
    }
    return userData
  } else {
    console.error(`User data not found for employeeID: ${employeeID}`)
    return null
  }
}

export function getWageTypeName(WageTypeNumber, wageTypeNames) {
  return wageTypeNames[WageTypeNumber] || 'Unknown Wage Type'
}

export async function fetchWageTypeNames() {
  try {
    const payrollSettingsRef = firestore.collection('settings').doc('payroll')
    const wageTypeNumbersSnapshot = await payrollSettingsRef.collection('WageTypeNumber').get()
    const wageTypeNames = {}
    wageTypeNumbersSnapshot.forEach((doc) => {
      const data = doc.data()
      wageTypeNames[data.TripletexNumber] = data.name
    })
    const wageTypeOptions = Object.keys(wageTypeNames).map((key) => ({
      value: key,
      text: wageTypeNames[key],
    }))
    return {
      wageTypeNames,
      wageTypeOptions,
    }
  } catch (err) {
    console.error('Error fetching wage type names:', err)
  }
}

export function sumPayLines(payLines) {
  let sum = 0
  payLines.forEach(line => {
    sum += Number(line.Count) * Number(line.Amount)
  })
  return sum.toFixed(2)
}

export async function changeTransactionsStatus(status, transactions) {
  const ref = firestore.collection('payrollTransactions')
  const transactionChunks = chunkArray(transactions, 500)

  for (const chunk of transactionChunks) {
    const batch = firestore.batch()

    chunk.forEach((transactionId) => {
      const transactionRef = ref.doc(transactionId)
      batch.update(transactionRef, { status })
    })

    await batch.commit()
  }
}

function chunkArray(array, chunkSize) {
  const chunks = []
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize))
  }
  return chunks
}

export function createRecruitment(data, referredBy) {
  const ref = firestore.collection('recruitment')
  const recruitment = {
    RecruitID: data.employeeID,
    RecruitName: data.fullName,
    Department: data.department,
    TipID: referredBy.value,
    TipName: referredBy.text,
    Status: {
      '1-month': {
        paid: false,
        date: null,
      },
      '3-month': {
        paid: false,
        date: null,
      },
      budget: {
        paid: false,
        date: null,
      },
    },
  }
  if (this.$store.state.user.isRecruiter) {
    recruitment.RecruiterName = this.$store.state.user.fullName
    recruitment.RecruiterID = this.$store.state.user.employeeID
  }
  return ref.add(recruitment)
}
